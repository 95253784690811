import { readonly } from "vue";

export default [
  {
    path: "/contrato",
    name: "contrato",
    component: () =>
      import(/* webpackChunkName: "solicitudesList"  */ "./layout.vue"),
    children: [
      {
        path: "proceso_ejecucion",
        name: "ejecucion-proceso",
        component: () =>
          import(/* webpackChunkName: "rolesList" */ "./proceso-index.vue"),
      },
      {
        path: "crear_proceso",
        name: "crear-proceso-index",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./vistaAgregarProceso.vue"
          ),
      },
      {
        path: "manto",
        name: "manto-proceso",
        component: () =>
          import(/* webpackChunkName: "rolesList" */ "./proceso-index.vue"),
      },
      {
        path: "asignacion-proceso",
        name: "asignacion-proceso",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./procesos_especificos/AsignacionProcesosEspecificos.vue"
          ),
      },
      {
        path: "asignacion-proceso/crear",
        name: "asignacion-proceso-crear",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./procesos_especificos/AsignacionProcesosEspecificosForm.vue"
          ),
      },
      {
        path: "asignacion-proceso/editar/:id",
        name: "asignacion-proceso-editar",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./procesos_especificos/AsignacionProcesosEspecificosForm.vue"
          ),
      },
      {
        path: "proceso_personal",
        name: "personal-proceso",
        component: () =>
          import(/* webpackChunkName: "rolesList" */ "./proceso-personal.vue"),
      },
      {
        path: "crear_proceso/:proceso",
        name: "crear-proceso",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./vistaAgregarProceso.vue"
          ),
      },
      {
        path: "autorizacion",
        name: "autorizacion-view",
        component: () =>
          import(/* webpackChunkName: "rolesList" */ "./vistaAutorizacion.vue"),
      },
      {
        path: "nombramiento-detalle/:id_nombramiento",
        name: "nombramiento-detalle",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./nombramiento/pages/NombramientoDetalle.vue"
          ),
      },
      {
        path: "nombramiento/:id_nombramiento",
        name: "nombramiento",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./nombramiento/pages/Nombramiento.vue"
          ),
      },
      {
        path: "/detalle_candidato-autorizacion/:id_persona/:id_solicitud",
        name: "detalle-candidato-autorizacion",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./pages/AutorizacionDinarh.vue"
          ),
      },
      {
        path: "/detalle_candidato-fases/:id_persona/:id_solicitud",
        name: "detalle-candidato-fases",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./pages/AutorizacionFases.vue"
          ),
      },
      {
        path: "/detalle_candidato-pre-firma/:id_persona/:id_solicitud",
        name: "detalle-candidato-pre-firma",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./pages/AutorizacionPreContrato.vue"
          ),
      },
      {
        path: "/detalle_candidato-nombramiento/:id_persona/:id_solicitud",
        name: "detalle-candidato-nombramiento",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./pages/FirmaContrato.vue"
          ),
      },
      {
        path: "/detalle_candidato-fases/:id_persona/:id_solicitud",
        name: "detalle-candidato-fases",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./pages/AutorizacionFases.vue"
          ),
      },
      {
        path: "/show-detalle_candidato/:id_persona/:id_solicitud",
        name: "show-detalle-candidato",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./pages/showPropuestaCandidato.vue"
          ),
        meta: {
          isShow: true,
        },
      },
      {
        path: "/detalle_candidato-correccion/:id_persona/:id_solicitud",
        name: "detalle-candidato-correccion",
        component: () =>
          import(
            /* webpackChunkName: "rolesList" */ "./pages/CorreccionAutorizacionFases.vue"
          ),
      },
    ],
  },
  {
    path: "/contrato/procesos",
    name: "autorizacion-procesos",
    component: () =>
      import(/* webpackChunkName: "rolesList" */ "./ProcesoAutorizacion.vue"),
  },
  {
    path: "/contrato/autorizacion",
    name: "proceso-autorizacion",
    component: () =>
      import(/* webpackChunkName: "rolesList" */ "./autorizacion-ministro.vue"),
  },
  {
    path: "/contrato/detalle_candidato/:id_persona/:id_solicitud",
    name: "detalle-candidato",
    component: () =>
      import(
        /* webpackChunkName: "rolesList" */ "./pages/PropuestaCandidato.vue"
      ),
  },
  {
    path: "/contrato/perfil_candidato/:id_persona/:id_solicitud/:id_asignacion",
    name: "perfil-candidato",
    component: () =>
      import(/* webpackChunkName: "rolesList" */ "./perfil-candidato.vue"),
  },
  {
    path: "/contrato/plantillas",
    name: "plantillas",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/plantillaIndex.vue"
      ),
  },
  {
    path: "/contrato/plantillas/crear",
    name: "plantillas-crear",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/plantillaForm.vue"
      ),
  },
  {
    path: "/contrato/plantillas/editar/:id",
    name: "plantillas-editar",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/plantillaForm.vue"
      ),
  },
  {
    path: "/contrato/plantillas/correlativo",
    name: "plantillas-correlativo",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/correlativoIndex.vue"
      ),
  },
  {
    path: "/contrato/plantilla/correlativo/crear",
    name: "plantilla-correlativo-crear",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/plantillaCorrelativoForm.vue"
      ),
  },
  {
    path: "/contrato/plantilla/correlativo/editar/:id",
    name: "plantilla-correlativo-editar",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/plantillaCorrelativoForm.vue"
      ),
  },
  {
    path: "/contrato/plantillas/footer",
    name: "plantillas-footer",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/footerIndex.vue"
      ),
  },
  {
    path: "/contrato/plantillas/footer/crear",
    name: "plantillas-footer-crear",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/plantillaFooterForm.vue"
      ),
  },
  {
    path: "/contrato/plantillas/footer/editar/:id",
    name: "plantillas-footer-editar",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/plantillaFooterForm.vue"
      ),
  },
  {
    path: "/contrato/plantillas/encabezado",
    name: "plantillas-encabezado",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/encabezadoIndex.vue"
      ),
  },
  {
    path: "/contrato/plantillas/encabezado/crear",
    name: "plantilla-encabezado-crear",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/plantillaEncabezadoForm.vue"
      ),
  },
  {
    path: "/contrato/plantillas/encabezado/editar/:id",
    name: "plantilla-encabezado-editar",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/plantillaEncabezadoForm.vue"
      ),
  },
  {
    path: "/contrato/plantillas/margenes/",
    name: "plantillas-margenes",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesList"  */ "./plantillas/margenesIndex.vue"
      ),
  },
];
