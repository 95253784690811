import { http_client } from "@/plugins/http_client";

const url = "/api/v1/nombramiento";
const getNombramientos = async (params = {}) =>
  await http_client(`${url}/`, params);
const updateNombramiento = async (id_nombramiento, body = {}) =>
  await http_client(`${url}/${id_nombramiento}`, body, "put");
const getNombramiento = async (id) =>
  await http_client(`${url}/${id}`, {}, "get");
const Nombramiento = async (params = {}) =>
  await http_client(url, params, "post");
const getAcreedores = async (params = {}) =>
  await http_client(`api/v1/acreedor`, params, "get");
const getTipoDocumento = async (params = {}) =>
  await http_client(`api/v1/tipo-documento`, params, "get");
const getListaDocumentosPersona = async (id_nombramiento, params = {}) =>
  await http_client(`${url}/persona/documento/${id_nombramiento}`, params, "get");
const postCuentaBancaria = async (id_nombramiento, params = {}) =>
  await http_client(`${url}/persona/cuenta-bancaria/${id_nombramiento}`, params, "post");
const postDocumentoPersona = async (id_nombramiento, params = {}) =>
  await http_client(`${url}/persona/documento/${id_nombramiento}`, params, "post");
const deleteDocumentoPersona = async (id_documento) =>
  await http_client(`${url}/persona/documento/${id_documento}`, {}, "delete");

export default {
  getNombramientos,
  updateNombramiento,
  getNombramiento,
  Nombramiento,
  getAcreedores,
  getTipoDocumento,
  getListaDocumentosPersona,
  postCuentaBancaria,
  postDocumentoPersona,
  deleteDocumentoPersona,
};