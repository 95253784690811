<template>
    <div
        :class="[
        `text-${heading ? heading : textHeader}`,
        `mb-${margin}`,
        `font-weight-${weight}`,
        `d-flex ${flex}`,
        `text-${transform}`,
        `text-${alignment}`,
         extraClasses
        ]"
        class="text-center"
        :style="textColor"
        v-text="text"
    >
    </div>
</template>
<script>

export default {
    props:{
        heading: String,
        text: String,
        size: String,
        weight: {
            type: String,
            default: 'bold'
        },
        spacing: String,
        margin: {
            typeof: String,
            default: 5,
        },
        flex: {
            type: String,
            default: 'justify-center',
        },
        textColor: {
            type: Object,
            default: ()=>({'color' : '#1C1E4D' })
        },
        transform: {
            type: String,
            default: ''
        },
        alignment: {
            type: String,
            default: 'center'
        },
	      extraClasses: {
						type: String,
						default: () => ''
				}
    },
    data:() =>({
    }),
    computed:{
        textHeader() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'h5'
            case 'sm': return 'h4'
            case 'md': return 'h3'
            case 'lg': return 'h2'
            case 'xl': return 'h2'
        }
        }
        // color(){
        //     return {'color' : this.textColor }
        // },        
    }
}
</script>